import axios from "axios";
import router from "@/router";
// import {useRouter} from "vue-router";
// const router = useRouter()

const header= ""

export function get(url,params){
    return  axios.get(header+url,{
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        // console.log(e)
    })
}
export function postLogin(url,params){
    return  axios.post(header+url,
        params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function post(url,params){
    return  axios.post(header+url,
        params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function put(url,params){
    return  axios.put(header+url,
        params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function pat(url,params){
    return  axios.patch(header+url,
        params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function del(url,params){
    return  axios.delete(header+url,{
        params
    }).then((res)=>{
        //判断本地有没有cookie
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}


// 配置响应拦截
axios.interceptors.response.use(res => {
    // if(res.status === 202){
    //     //返回首页
    //     window.location.href="/#/"
    // }else {
        return res
    // }
    }, error => {
    if(error.response.status === 401){
        //返回首页
        window.location.href="/#/"
    }else {
        return error.response
    }
})
