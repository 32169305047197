import {get, postLogin, post, del, put,pat} from '../servise/base'
//获取医院名称
export function getHospital(province,city){
    return get('/api/v1/hospitals/' + province + '/' + city )
}
//登录
export function login(data){
    return postLogin('/api/login',data)
}
//日历
export function getCalendar(year){
    return get('/api/v1/data_statistics_overview', {year})
}
//数据统计 获取echart图
export function getEcharts(year,hospitalId,range,province,city){
    return get('/api/v1/data_statistics/'+range,{year,hospitalId,province,city} )
}

//获取上报数据
export function getReportedData(id){
    return get('/api/v1/report_data/'+id )
}

//上报明细
export function UpDetail(hospitalId,year,province,city){
    return get('/api/v1/data_statistics_detail', {hospitalId,year,province,city} )
}

//获取用户详细信息，判断是否显示登录对话框
export function loginIsShow(){
    return get('/api/v1/user_info' )
}

//退出
export function quitLogin(){
    return post('/api/v1/logout' )
}
//提交
export function submitPost(data){
    return post('api/v1/report_data',data)
}
//修改
export function submitPut(id,data){
    return put('api/v1/report_data/'+id,data)
}

// 删除  上报明细
export function delUPList(id){
    return del('/api/v1/report_data/'+id )
}

//获取首页轮播图图片列表
export function getImgList(){
    return get('/api/banners' )
}

//导出
export function getEduce(hospital_name){
    return get('/api/v1/export_data_statistics_detail', {hospital_name})
}
//修改密码
export function patPassword(data){
    return pat('/api/v1/user', data)
}