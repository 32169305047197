<template>
  <a-spin :spinning="imgList.spinning">
<!--  轮播图-->
  <div style="width: 100%;height: 450px">
    <a-carousel arrows autoplay>
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 120px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 130px">
          <right-circle-outlined />
        </div>
      </template>
      <div class="imgStyle" v-for="(item,index) in imgList.imgUrl" :key="index">
        <img
            @click="imgBtn(item.targetUrl)"
            style="width: 100%;height: 100%"
            :src="item.imageUrl">
      </div>
    </a-carousel>
  </div>
<!-- 个人头像 -->
  <div v-if="showData.imgShow " class="personal-center">
    <div class="personal-center-img">
      <a-dropdown>
          <img
              @click.prevent
              class="personal-center-img-img"
              :src="res.imageUrl === ''?showData.imgNoLogin :res.imageUrl">
        <template #overlay>
          <a-menu @click="quitTest">
            <a-menu-item style="height: 30px!important;" key="1">退出</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <span style="margin-top: 45px;margin-left: 10px;color: #dcdbdb;font-weight: bold">您好，{{res.tel}}</span>
  </div>
<!--  登录对话框-->
  <div v-if="!showData.loginShow" class="login">
    <div class="login-content">
      <div class="login-content-input">
        <a-input
            v-model:value="loginForm.tel"
            placeholder="请输入用户名"
            @pressEnter="loginBtn"
            style="width: 85%;margin-bottom: 30px"/>
        <a-input-password
            v-model:value="loginForm.password"
            placeholder="请输入密码"
            @pressEnter="loginBtn"
            style="width: 85%;margin-bottom: 10%" />
        <a-button
            type="primary"
            @click="loginBtn"
            style="width: 50%;margin-left: 20%">登录</a-button>
      </div>
    </div>
  </div>
<!-- 修改密码对话框-->
    <div class="passWord" v-show="changePassword.passShow">
<!--    <div class="passWord" v-show="true">-->
      <div class="passWord-content">
        <div style="width: 100%;text-align: center;padding-top: 5%;font-size: 20px;color: #5d5c5c">修改密码</div>
        <div class="passWord-content-input">
          <div style="display: flex">
            <div style="margin-top: 2%;width: 70px;text-align: right">新密码：</div>
            <a-input-password
                @blur="onBlurPass(changePassword.firstPass)"
                v-model:value="changePassword.firstPass"
                placeholder="请输入新密码"
                @pressEnter="changePasswordBtn"
                style="width: 60%;margin-bottom: 5px"/>
          </div>
          <span
              v-show="passAleart.pass"
              style="width: 100%;text-align: center;display: block;color: red;font-size: 12px;">
            密码必须大于8位，且包含字母与数字
          </span>
          <div style="display: flex;margin-top: 5px;">
            <div style="margin-top: 2%;width: 70px;text-align: right">
              确认密码：
            </div>
            <a-input-password
                v-model:value="changePassword.confirmPassword"
                placeholder="请输入确认密码"
                @pressEnter="changePasswordBtn"
                style="width: 60%;margin-bottom: 10%" />
          </div>
          <a-button
              type="primary"
              @click="changePasswordBtn"
              style="width: 50%;margin-left: 20%">提 交</a-button>
        </div>
      </div>
    </div>
<!--标题-->
<div class="title-sty">
  <span class="title-sty-font">山东省重症医学质量控制平台</span>
</div>
<!--  导航栏-->
  <div class="nav">
    <div style="width: 33%;cursor: pointer">
      <div class="nav-icon"  @click="qualityControlBtn">
        <div class="nav-icon-div">
          <img
              class="imgStyleClass"
              src="../../assets/navLeft.png">
        </div>
        <div class="nav-content-font">
          数据上报
        </div>
      </div>
    </div>

    <div style="width: 33%;cursor: pointer" >
      <div class="nav-icon-two"  @click="statisticsBtn">
        <div class="nav-icon-div">
          <img
              class="imgStyleClass"
              src="../../assets/navMiddle.png">
        </div>
        <div class="nav-content-font">
          数据统计
        </div>
      </div>
    </div>

    <router-link style="width: 33%;cursor: pointer" to="">
      <div class="nav-icon-three" @click="moreBtn">
        <div class="nav-icon-div">
          <img
              class="imgStyleClass"
              src="../../assets/navRight.png">
        </div>
        <div class="nav-content-font">
          直播课堂
        </div>
      </div>
    </router-link>

  </div>
<!--  直播课堂-->
  <div class="Live-class">
    <a-row>
      <a-col :span="12" class="Live-class-title">点播推荐</a-col>
      <a-col :span="12" class="Live-class-more">
        <a @click="moreBtn" class="Live-class-more">更多</a>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6" v-for="(item,index) in liveData.liveList" :key="index">
        <a-card hoverable style="width: 99%;margin: auto" @click="liveBtn(item.id)">
          <template #cover>
            <img
                alt="example"
                style="width: 100%;height: 150px"
                :src="item.cover[0]"
            />
          </template>
          <a-card-meta
              :title="item.title">
            <template #description>
              <div style="height: 50px">
                <div style="font-size: 6px">{{item.expert.name}}</div>
                <div style="font-size: 6px">{{item.expert.hospital}}</div>
              </div>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </div>
  <div style="height: 100px;text-align: center;width: 100%;line-height: 120px;color: #4aa3ee">
    ©2022 - 山东省重症监护质量控制平台 版权所有 广州市优屏科技有限公司 <a
      class="navClass"
      @click="footerBtn">   粤ICP备15073581号</a>
  </div>
  </a-spin>
</template>
<script>
import { LeftCircleOutlined, RightCircleOutlined,AreaChartOutlined, CloseCircleOutlined} from '@ant-design/icons-vue';
import {defineComponent, reactive, ref, toRef} from 'vue';
import {getImgList, login, loginIsShow, patPassword, quitLogin} from "@/servise/api";
import {useRoute, useRouter} from "vue-router";
import {message} from "ant-design-vue";
import axios from "axios";
import {setPassExp} from '../../util/passExp'
import {useStore} from "vuex";
export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    AreaChartOutlined,
    CloseCircleOutlined
  },
  setup(){
    let store = useStore()
    const passAleart = reactive({
      pass:false,
    })
    function onBlurPass(pass){
    // 密码必须大于8位，且包含字母与数字
      if(!setPassExp(pass) || pass.length < 8){
        passAleart.pass = true
      }else {
        passAleart.pass = false
      }
    }
    const liveData = reactive({
      liveList:[],
      title:'1',
      name:'2',
      school:'3',
    })
    axios.get('https://yunicu-api.yunicu.com/api/v2/app/vods?page_size=4').then(res=>{
      if(res.data.code === 0){
        liveData.liveList = res.data.data.rows
      }else {
        message.warning("失败，请联系管理员！"+res.data.message)
      }
    })
    //获取banner图
    const imgList = reactive({
      imgUrlList:[],
      imgUrl:[],
      spinning:false
    })
    imgData()
    async function imgData() {
      // imgList.spinning = true
      imgList.imgUrlList = await getImgList()
      imgList.imgUrl = imgList.imgUrlList.data
      // imgList.spinning = false
    }
    const route = useRoute()
    const router = useRouter()
    const loginResponse = reactive({
      response:[],
    })
    const loginForm = reactive({
      tel:'',
      password:'',
    })
    const showData = reactive({
      loginShow:true,
      imgNoLogin:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi.qqkou.com%2Fi%2F1a2742296696x681526349b15.jpg&refer=http%3A%2F%2Fi.qqkou.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650784520&t=1b63b14e9d5e2d5c71629ec4543fbc18',
      isLogin:0,
      imgShow:false,
    })
    //登陆的点击按钮
    async function loginBtn() {
      //点击登录按钮隐藏登录对话框显示个人信息
      if(loginForm.tel !== null &&
          loginForm.tel !== undefined &&
          loginForm.tel !== '' &&
          loginForm.password !== null &&
          loginForm.password !== undefined &&
          loginForm.password !== ''){
        const response = await login(loginForm)
        if(response.code === 0){
          showData.isLogin = 1
          if(response.status === 202){
            message.warning("登录成功，首次登录请修改登录密码！")
            changePassword.passShow = true
          }else {
            message.success("登录成功！")
          }
          showData.loginShow = true
          await isShow()
        }else {
          message.warning(response.message);
        }
      }else {
        message.warning("请先输入账号与密码！")
      }
    }
    //获取用户信息，判断童虎是否登录，登录显示信息，隐藏登录对话框

    const res = reactive({
      response:[],
      tel:'',
      imageUrl:'',
      quit:''
    })
    isShow()
    async function isShow(){
      res.response = await loginIsShow()
      // if(res.response.status === 202){
      //   changePassword.passShow = true
      // }
      if (res.response) {
        if(res.response.status === 202){
          changePassword.passShow = true
        }else {
          showData.imgShow = true
        }
        if(res.response.code === 0 && res.response.status === 200){
          showData.loginShow = true
          res.tel = res.response.data.name
          if(res.response.data.avatar === undefined){
            res.imageUrl = 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi.qqkou.com%2Fi%2F1a2742296696x681526349b15.jpg&refer=http%3A%2F%2Fi.qqkou.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650784520&t=1b63b14e9d5e2d5c71629ec4543fbc18'
          }else {
            res.imageUrl = res.response.data.avatar
          }
        }else {
          message.warning(res.response.message)
          showData.loginShow = false
        }
      }else {
        showData.loginShow = false
      }
    }
    function quitBtn(key){
      // console.log(`Click on item ${key}`)
    }
    async function quitTest() {
      res.quit = await quitLogin()
      if(res.quit.code === 0){
        message.success("退出登录！")
        await imgData()
       // 刷新界面
        location.reload()
       //  imgList.spinning = false
      }else {
        message.warning(res.quit.message)
      }
    }
    //视频跳转
    function liveBtn(id){
      window.open('http://www.yunicu.com/#/single?id='+id)
    }
    function moreBtn(){
      window.open('http://www.yunicu.com/')
    }
    function footerBtn(){
      window.open('https://beian.miit.gov.cn')
    }
    function imgBtn(url){
      window.open(url)
    }
    //修改密码对话框
    const changePassword = reactive({
      passShow:false,
      firstPass:'',
      confirmPassword:'',
    })
    async function changePasswordBtn() {
      if (changePassword.firstPass !== undefined && changePassword.firstPass !== '' && changePassword.firstPass !== null &&
          changePassword.confirmPassword !== undefined && changePassword.confirmPassword !== '' && changePassword.confirmPassword !== null &&
          changePassword.firstPass === changePassword.confirmPassword && passAleart.pass === false) {
        let data = {
          "password": changePassword.firstPass
        }
        const response = await patPassword(data)
        if(response.code === 0){
          message.success("修改成功！")
          store.commit('isPassWord')
          store.state.isPassWord = true
          //关闭对话框
          changePassword.passShow = false
          let data = {
            tel:loginForm.tel,
            password:changePassword.firstPass,
          }
          const responses = await login(data)
          if(responses.code === 0){
            await isShow()
          }
        }else {
          message.error(response.message)
        }
      } else {
        message.warning("输入的密码不一致或密码必须大于8位，且包含字母与数字！")
      }
    }
    async function qualityControlBtn() {
      const response = await loginIsShow()
      if (response.status === 200){
        router.push({
          path: '/qualityControl'
        })
      } else {
          changePassword.passShow = true
          message.warning("登录成功，首次登录请修改登录密码")
      }
    }
    async function statisticsBtn() {
      const response = await loginIsShow()
      if (response.status === 200) {
        router.push({
          path: '/qualityControl/statistics'
        })
      } else {
          changePassword.passShow = true
          message.warning("登录成功，首次登录请修改登录密码")
      }
    }
    return{
      loginBtn,
      liveData,
      showData,
      loginForm,
      changePassword,
      loginResponse,
      res,
      qualityControlBtn,
      statisticsBtn,
      changePasswordBtn,
      quitBtn,
      quitTest,
      imgList,
      liveBtn,
      moreBtn,
      footerBtn,
      imgBtn,
      onBlurPass,
      passAleart
    }
  }
});
</script>
<style scoped lang="scss">
.imgStyle{
  width: 80%;
  height: auto;
  overflow: hidden;
}
.personal-center{
  width: 200px;
  height: auto;
  position: absolute;
  top: -5px;
  right: 10%;
  display: flex;
  z-index: 1;
  .personal-center-img{
    margin-top: 30px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    .personal-center-img-img{
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
}
.login{
  width: 23%;
  height: 18vw;
  position: absolute;
  top: 6%;
  left: 60%;
  background-color: rgba(243, 245, 246, 0.2);
  border-radius: 10px;
  box-shadow: 0px 0px 3px 1px #adadad;
  .login-content{
    width: 90%;
    height: 90%;
    border-radius: 10px;
    margin:15px 15px;
    background-color: rgba(252, 253, 253, 0.9);
    .login-content-input{
      width: 100%;
      margin-left: 5%;
      padding-top: 15%;
    }
  }
}
.passWord{
  width: 23%;
  height: 18vw;
  position: absolute;
  top: 6%;
  left: 60%;
  background-color: rgba(243, 245, 246, 0.2);
  border-radius: 10px;
  box-shadow: 0px 0px 3px 1px #adadad;
  .passWord-content{
    width: 90%;
    height: 90%;
    border-radius: 10px;
    margin:15px 15px;
    background-color: rgba(252, 253, 253, 0.9);
    .passWord-content-input{
      width: 100%;
      margin-left: 5%;
      padding-top: 5%;
    }
    .closeIcon{
      width: 100%;
      text-align: right;
      padding-right: 20px;
      padding-top: 10px;
      cursor: pointer;
      font-size: 20px;
      color: #a8a5a5;
    }
  }
}
.nav{
  width: 60%;
  height: 150px;
  background-color: #fff;
  margin: auto;
  position: relative;
  top: -160px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #d7d6d6;
  display: flex;
  justify-content: space-around;
  .nav-icon{
    width: 100%;
    height: 130px;
    margin-top: 20px;
  }
  .nav-icon-div{
    width: 100%;
    text-align: center;
  }
  .nav-icon-two{
    width: 100%;
    margin-top: 20px;
    height: 130px;
    border-left: 1px solid #f1efef;
    border-right: 1px solid #f1efef;
  }
  .nav-icon-three{
    width: 100%;
    margin-top: 20px;
    height: 130px;
  }
  .nav-content-icon{
    text-align: center;
        font-size: 80px;
        color: #2990d3;
      }
  .nav-content-font{
    color: #248ad8;
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
  }
  .imgStyleClass{
    width: 60px;
    height: 60px;
    margin-top: 10px;
  }
}
.Live-class{
  width: 65%;
  height: 200px;
  margin: auto;
  position: relative;
  top: -120px;
  .Live-class-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .Live-class-more{
    text-align: right;
    color: darkgrey;
  }
  .Live-class-more:hover{
    color: #2990d3;
  }
}
.title-sty{
  height: 100px;
  line-height:100px;
  background: -webkit-gradient(linear,0% 0%,0% 100%,from(#111010),to(rgb(0,0,0,0)));
  position: relative;
  top: -450px;
  .title-sty-font{
    color: #fff;
    padding-left: 15%;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 5px;
  }
}
.navClass{
  color: #4aa3ee;
  margin-left: 5px;
}
.navClass:hover{
  color: #3d3d3d;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  width: auto;
  overflow: hidden;
}
.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 40px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.5;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.8;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>