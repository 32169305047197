import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import {
    Button, Form, Input, Checkbox, DatePicker,
    Carousel, Row, Col, Card, Alert, Tooltip, Popover,
    Calendar, Select, Radio, Table, ConfigProvider, Popconfirm,
    Dropdown, Menu, Spin
} from 'ant-design-vue';
// import axios from "axios";

const app = createApp(App)
app.use(store)
    .use(router)
    .use(Button)
    .use(Form)
    .use(Input)
    .use(Checkbox)
    .use(DatePicker)
    .use(Carousel)
    .use(Row)
    .use(Col)
    .use(Card)
    .use(Alert)
    .use(Tooltip)
    .use(Popover)
    .use(Calendar)
    .use(Select)
    .use(Radio)
    .use(Table)
    .use(ConfigProvider)
    .use(Popconfirm)
    .use(Dropdown)
    .use(Menu)
    .use(Spin)
    .mount('#app')


// 这里拦截401错误，并重新跳入登页
// axios.interceptors.response.use(
//     response => {
//         // console.log("response.use",response.request.responseURL)
//         // if (response.status === '401' ) {
//         //     console.log("!!!")
//         //     //返回首页
//         //     window.location.href="/#/"
//         // } else {
//         //     return response;
//         // }
//     }, error => {
//         console.log("1",error)
//         if (error.data.status === '401') {
//             console.log("!!!")
//             //返回首页
//             window.location.href="/#/"
//         }
//     }
// );