import { createStore } from 'vuex'

export default createStore({
  state: {
    listId:'',
    count:0,
    getTimeName:{
      year:'',
      id:'',
    },
    hosId:'',
    hosYear:'',
    hosName:'',
    cityName:'',
    provinceName:'',
    //提交
    submit:'',
    //修改
    modification:'',
    //显示修改按钮还是提交按钮  1提交按钮   0 修改按钮
    isShowBtn:true,
  //  是否修改密码
    isPassWord:false,
  },
  getters: {
  },
  mutations: {
    tranListId(state,listId){
      state.listId = listId
    },
    isPassWord(state,isPassWord){
      state.isPassWord = isPassWord
    }
  },
  actions: {
    tranListIdFun(context,listId){
      context.commit("tranListId",listId)
    },
    isPassWord(context,isPassWord){
      context.commit('isPassWord',isPassWord)
    }
  },
  modules: {
  },
})
