import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/content/HomePage'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component:HomePage
  },
  {
    path: '/qualityControl',
    name: 'QualityControl',
    component:()=>import('../components/QualityControl'),
    children:[
      {
        path: '',
        name: 'DataReport',
        component:()=>import('../views/dataAnalysis/DataReport')
      },
      {
        path: 'detail',
        name: 'ReportDetail',
        component:()=>import('../views/dataAnalysis/ReportDetail')
      },
      {
        path: 'statistics',
        name: 'statistics',
        component:()=>import('../views/dataAnalysis/DataStatistics'),
        children:[
          {
            path: '',
            name: 'statisMonth',
            component:()=>import('../views/dataAnalysis/statisMonth')
          },
          {
            path: 'statisYear',
            name: 'statisYear',
            component:()=>import('../views/dataAnalysis/statisYear')
          },
          {
            path: 'statisSeason',
            name: 'statisSeason',
            component:()=>import('../views/dataAnalysis/statisSeason')
          },
        ]
      },
    ]
  },

  // {
  //   path: '/test',
  //   name: 'test',
  //   component:test
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
