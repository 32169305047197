<template>
<!--  <QualityControl></QualityControl>-->
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<style lang="scss">

</style>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
// import QualityControl from "@/components/QualityControl";
import {useRoute, useRouter} from "vue-router";
import _default from "ant-design-vue/es/color-picker";
import {watch} from "vue";
export default {
  // components: {QualityControl}
  setup(){
    const route = useRoute()
    const router = useRouter()

    // router.beforeEach((to,form,next)=>{
    //   watch(()=>to,()=>{
    //
    //   })
    // })

    return {
      locale: zhCN,
    };
  }
}
</script>