//密码必须大于8位，且包含字母与数字
export function setPassExp(pass){
    // 0-9
    const arrNumber = /\d+/;
    //大小写字母
    const arrString = /[a-zA-Z]+/;
    if(arrNumber.test(pass) && arrString.test(pass)){
        return true
    }else {
        return false
    }
}